import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  NavigationEnd,
  ResolveEnd,
  ResolveStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../../services/layout.service';
import { filter, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../../core/services/auth.service';
import { Notification } from '../../../models/notification.model';
import * as moment from 'moment';
import { MqttWrapperService } from '../../../services/mqtt-wrapper.service';
import { ConfigService } from '../../../services/config.service';
import { MqttTopics } from '../../../enums/mqtt-topics';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.template.html',
  changeDetection: ChangeDetectionStrategy.Default,
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  @Output() updateView: EventEmitter<any> = new EventEmitter();
  private destroy$: Subject<boolean> = new Subject<boolean>();
  mqttTopics = MqttTopics;

  public isModuleLoading: Boolean = false;
  private moduleLoaderSub: Subscription;
  private layoutConfSub: Subscription;
  private routerEventSub: Subscription;
  public layoutConf: any = {};
  network;
  notifications: Notification[] = [];
  notifsNumber: number;
  constructor(
    private router: Router,
    public translate: TranslateService,
    private layout: LayoutService,
    private cdr: ChangeDetectorRef,
    private mqttWrapperService: MqttWrapperService,
    private authService: AuthService,
    private configService: ConfigService,
  ) {
    // Close sidenav after route change in mobile
    this.routerEventSub = router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((routeChange: NavigationEnd) => {
        this.layout.adjustLayout({ route: routeChange.url });
        this.scrollToTop();
      });

    // Translator init
    const browserLang: string = translate.getBrowserLang();
    translate.setDefaultLang(browserLang.match(/en|fr/) ? browserLang : 'fr');
  }
  ngOnInit() {
    const tokenData = this.authService.getTokenData();
    if (tokenData) {
      this.network = tokenData.network;
    }

    // this.layoutConf = this.layout.layoutConf;
    this.layoutConfSub = this.layout.layoutConf$.subscribe(layoutConf => {
      this.layoutConf = layoutConf;
      this.cdr.markForCheck();
    });
    // FOR MODULE LOADER FLAG
    this.moduleLoaderSub = this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart || event instanceof ResolveStart) {
        this.isModuleLoading = true;
      }
      if (event instanceof RouteConfigLoadEnd || event instanceof ResolveEnd) {
        this.isModuleLoading = false;
      }
    });
    // notifications
    // subscribe to all needed topic
    const config = this.configService.readConfig();
    this.mqttWrapperService.connect({
      hostname: config.mqtt.hostname,
      protocol: config.mqtt.protocol,
      username: tokenData.mqtt_user,
      password: tokenData.mqtt_password,
    });
    // Subscribe to incident topic
    for (const topic in this.mqttTopics) {
      this.subscribeToTopic(topic);
    }
  }

  private subscribeToTopic(topic: string) {
    this.mqttWrapperService
      .observe(`${this.network}/${this.mqttTopics[topic]}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        message => {
          const notification = JSON.parse(message.payload.toString());
          notification.time = moment().format('HH:mm');
          if (notification) {
            this.notifications.unshift(notification);
            this.notifsNumber = this.notifications.length;
          }
        },
        err => {
          console.error(err);
        },
      );
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.layout.adjustLayout(event);
  }

  scrollToTop() {
    if (document) {
      setTimeout(() => {
        let element;
        if (this.layoutConf.topbarFixed) {
          element = <HTMLElement>document.querySelector('#rightside-content-hold');
        } else {
          element = <HTMLElement>document.querySelector('#main-content-wrap');
        }
        element.scrollTop = 0;
      });
    }
  }
  ngOnDestroy() {
    if (this.moduleLoaderSub) {
      this.moduleLoaderSub.unsubscribe();
    }
    if (this.layoutConfSub) {
      this.layoutConfSub.unsubscribe();
    }
    if (this.routerEventSub) {
      this.routerEventSub.unsubscribe();
    }
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  closeSidebar() {
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed',
    });
  }

  sidebarMouseenter(e) {
    if (this.layoutConf.sidebarStyle === 'compact') {
      this.layout.publishLayoutChange({ sidebarStyle: 'full' }, { transitionClass: true });
    }
  }

  sidebarMouseleave(e) {
    if (this.layoutConf.sidebarStyle === 'full' && this.layoutConf.sidebarCompactToggle) {
      this.layout.publishLayoutChange({ sidebarStyle: 'compact' }, { transitionClass: true });
    }
  }

  updateNotifNumber(event: any) {
    this.notifsNumber = event;
  }

  updateNotificationsArray(event: any) {
    this.notifications = event;
  }
}
