import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// SERVICES
import { ThemeService } from './services/theme.service';
import { NavigationService } from './services/navigation.service';
import { RoutePartsService } from './services/route-parts.service';
import { AppConfirmService } from './services/app-confirm/app-confirm.service';

import { SharedComponentsModule } from './components/shared-components.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts/core';

export const MY_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM/YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM/YYYY',
  },
};

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      countDuplicates: true,
    }),
    ReactiveFormsModule,
    NgxEchartsModule.forRoot({ echarts }),
  ],
  providers: [
    ThemeService,
    NavigationService,
    RoutePartsService,
    AppConfirmService,
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  exports: [
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    TranslateModule,
    LeafletModule,
    ReactiveFormsModule,
    CommonModule,
  ],
})
export class SharedModule {}
