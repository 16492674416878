<mat-toolbar class="topbar flex-justify-content-space-between">
  <button
    *ngIf="layoutConf.sidebarStyle !== 'compact'"
    mat-icon-button
    id="header-side-button-hide-open-side-nav"
    (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open">
    <mat-icon>menu</mat-icon>
  </button>
  <div class="flex-row-pure flex-justify-content-end flex-align-items-center row-gap-10">
    <button *ngIf="tokenData.rights.admin" mat-stroked-button [routerLink]="['/rights']" id="header-side-button-rights">
      <img ngSrc="assets/images/key_dark_blue.svg" width="17" height="17" alt="key" />
      {{ 'Rights.title' | translate }}
    </button>
    <mat-select
      style="width: 150px"
      [placeholder]="'AI_Configuration.network.title' | translate"
      [value]="agentLoginsSelected"
      (selectionChange)="onChangeNetwork($event.value, agentLoginsSelected)"
      id="header-side-button-network-switcher">
      <mat-option
        *ngFor="let agentLogin of agentsLogins"
        [value]="agentLogin"
        [id]="'header-top-button-network-dropdown-' + agentLogin.network">
        {{ agentLogin.network }}
      </mat-option>
    </mat-select>
    <button mat-button [matMenuTriggerFor]="menu" id="header-side-button-language-switcher">
      <span class="flag-icon {{ currentLang.flag }} mr-05"></span>
      <span>{{ currentLang.name }}</span>
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        *ngFor="let lang of availableLangs"
        (click)="setLang(lang)"
        [id]="'header-top-button-language-dropdown-' + lang.name">
        <span class="flag-icon {{ lang.flag }}"></span>
        <span>{{ lang.name }}</span>
      </button>
    </mat-menu>
    <button
      mat-icon-button
      [routerLink]="['/profile']"
      matTooltip="{{ 'Profile.title' | translate }}"
      id="header-side-button-profil">
      <mat-icon>account_box</mat-icon>
    </button>
    <button
      mat-icon-button
      matTooltip="Notifications"
      (click)="toggleNotific()"
      [style.overflow]="'visible'"
      id="header-side-button-notification">
      <mat-icon>notifications</mat-icon>
      <span *ngIf="notifsNumber" class="notification-number mat-bg-warn">{{ notifsNumber }}</span>
    </button>
    <button
      mat-icon-button
      matTooltip="{{ 'common.logout' | translate }}"
      (click)="logOut()"
      id="header-side-button-logout">
      <mat-icon>exit_to_app</mat-icon>
    </button>
  </div>
</mat-toolbar>
