import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './core/guards/auth.guard';
import { RolesScreen } from './shared/enums/roles-screen.enum';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
        data: { title: 'Authentication' },
      },
      {
        path: 'privacy',
        loadChildren: () => import('./modules/privacy/privacy.module').then(m => m.PrivacyModule),
        data: { title: 'Privacy', breadcrumb: 'Privacy.title' },
      },
    ],
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Dashboard', breadcrumb: 'common.quick_access' },
      },
      {
        path: 'general-view',
        loadChildren: () => import('./modules/general-view/general-view.module').then(m => m.GeneralViewModule),
        data: { title: 'General View' },
      },
      {
        path: 'hr',
        loadChildren: () => import('./modules/hr/hr.module').then(m => m.HRModule),
        data: { title: 'HR', breadcrumb: 'Human_Resources.HR' },
      },
      {
        path: 'infrastructure',
        loadChildren: () => import('./modules/infrastructure/infrastructure.module').then(m => m.InfrastructureModule),
        data: { title: 'Infrastructure', breadcrumb: 'Infrastructure.title' },
      },
      {
        path: 'ai-configuration',
        loadChildren: () =>
          import('./modules/ai-configuration/ai-configuration.module').then(m => m.AIConfigurationModule),
        data: { title: 'AI', breadcrumb: 'AI_Configuration.title' },
      },
      {
        path: 'fraud-control',
        loadChildren: () => import('./modules/fraud-control/fraud-control.module').then(m => m.FraudControlModule),
        data: { title: 'Fraud Control', breadcrumb: 'Fraud_Control.title' },
      },
      {
        path: 'profile',
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule),
        data: { title: 'Profile', breadcrumb: 'Profile.title' },
      },
      {
        path: 'incidents',
        loadComponent: () => import('./modules/incidents/pages/incidents.component').then(m => m.IncidentsComponent),
        canActivate: [AuthGuard],
        data: { title: 'Incidents', breadcrumb: 'incidents.title', roles: RolesScreen.INCIDENTS },
      },
      {
        path: 'rights',
        loadComponent: () => import('./modules/rights/rights/rights.component').then(m => m.RightsComponent),
        canActivate: [AuthGuard],
        data: { title: 'Rights', breadcrumb: 'Rights.title', roles: RolesScreen.ADMIN },
      },
      {
        path: 'devices',
        loadChildren: () => import('./modules/devices/devices.module').then(m => m.DevicesModule),
        data: { title: 'Devices', breadcrumb: 'Devices.title' },
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'auth/404',
  },
];
