import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent {
  @Input() public menuItems: any[] = [];
  @Input() public hasIconTypeMenuItem: boolean;
  @Input() public iconTypeMenuTitle: string;
}
