import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm',
  template: `<h1 matDialogTitle class="mb-05">{{ data.title }}</h1>
    <div mat-dialog-content class="mb-1">{{ data.message }}</div>
    <div mat-dialog-actions class="flex-row-pure flex-justify-content-space-between" id="app-confirm-presence">
      <button
        mat-stroked-button
        mat-dialog-close
        color="warn"
        [mat-dialog-close]="false"
        id="app-confirm-button-cancel">
        {{ 'common.cancel' | translate }}
      </button>
      <button mat-flat-button color="accent" [mat-dialog-close]="true" id="app-confirm-button-confirm">Ok</button>
    </div>`,
})
export class AppComfirmComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
