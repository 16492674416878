export enum RolesScreen {
  ACTIVITY_REPORTS = 'activity_reports',
  ADMIN = 'admin',
  AGENTS = 'agents',
  AI_PARAMETERS = 'ai_parameters',
  ASSIGNMENTS = 'assignments',
  BLACKLISTED_STATIONS = 'blacklisted_stations',
  CALENDAR = 'calendar',
  CONFIGURATIONS = 'configurations',
  CONFIG_NETWORK = 'config_network',
  CONFIG_OPERATIONS = 'config_operations',
  CONTRAVENTIONS = 'contraventions',
  CONTROL_STATISTICS = 'control_statistics',
  DARS_REPORTS = 'dars_reports',
  DARS_REPORTS_STATISTICS_BUS = 'dars_reports_statistics_bus',
  DASHBOARD = 'dashboard',
  DEFINITION = 'definition',
  DEVICES = 'devices',
  EXTRACTIONS = 'extractions',
  FRAUD_CONTROL = 'fraud_control',
  GENERAL_VIEW = 'general_view',
  HR = 'hr',
  INCIDENTS = 'incidents',
  INFRASTRUCTURE = 'infrastructure',
  INVENTORY = 'inventory',
  LINES = 'lines',
  MANDATORY_STATIONS = 'mandatory_stations',
  TEAMS = 'teams',
  OBJECTIVES = 'objectives',
  PLANNING = 'planning',
  PROFILE = 'profile',
  SHIFTS = 'shifts',
  TOPOLOGY = 'topology',
}
