<div class="sidebar-panel">
  <div
    id="scroll-area"
    [perfectScrollbar]="{}"
    class="navigation-hold flex-column-pure flex-justify-content-space-between">
    <div class="sidebar-hold">
      <div class="branding">
        <img src="assets/images/login/octocity_logo_dark_bg.svg" alt="" class="app-logo" />
        <span style="margin: auto" *ngIf="layoutConf.sidebarStyle !== 'compact'"></span>
      </div>

      <app-sidenav
        [menuItems]="menuItems"
        [hasIconTypeMenuItem]="hasIconTypeMenuItem"
        [iconTypeMenuTitle]="iconTypeMenuTitle">
      </app-sidenav>
    </div>
    <div class="footer">
      <p>
        {{ 'common.powered_by' | translate }} ©<a href="https://datategy.net/" target="_blank">Datategy</a> 2020 v{{
          version
        }}
      </p>
    </div>
  </div>
</div>
