<div class="sidenav-hold">
  <div class="icon-menu mb-1" *ngIf="hasIconTypeMenuItem">
    <!-- Icon menu separator -->
    <div class="mb-1 nav-item-sep">
      <span class="text-muted icon-menu-title">{{ iconTypeMenuTitle }}</span>
    </div>
    <!-- Icon menu items -->
    <div class="icon-menu-item" *ngFor="let item of menuItems">
      <button
        *ngIf="!item.disabled && item.type === 'icon'"
        mat-raised-button
        [matTooltip]="item.tooltip"
        routerLink="/{{ item.state }}"
        routerLinkActive="mat-bg-primary">
        <mat-icon>{{ item.icon }}</mat-icon>
      </button>
    </div>
  </div>
  <ul appDropdown class="sidenav">
    <li *ngFor="let item of menuItems" appDropdownLink routerLinkActive="open">
      <div class="nav-item-sep" *ngIf="item.type === 'separator'">
        <!--<mat-divider></mat-divider>-->
        <span class="text-muted">{{ item.name | translate }}</span>
      </div>
      <div *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'" class="lvl1">
        <a
          routerLink="/{{ item.state }}"
          appDropdownToggle
          matRipple
          *ngIf="item.type === 'link'"
          [id]="'side-nav-button-item-dropdown-' + item.name">
          <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
          <img *ngIf="item.iconUrl" class="menu-icon" [src]="item.iconUrl" />
          <span class="item-name lvl1">{{ item.name | translate }}</span>
          <span
            class="menuitem-badge mat-bg-{{ badge.color }}"
            [ngStyle]="{ background: badge.color }"
            *ngFor="let badge of item.badges">
            {{ badge.value }}
          </span>
        </a>
        <!-- DropDown -->
        <a
          *ngIf="item.type === 'dropDown'"
          appDropdownToggle
          matRipple
          class="flex-row flex-justify-content-space-between"
          [id]="'side-nav-button-lvl1-item-dropdown-' + item.name">
          <div class="flex-row">
            <mat-icon *ngIf="item.icon">{{ item.icon }}</mat-icon>
            <img *ngIf="item.iconUrl" class="menu-icon" [src]="item.iconUrl" />
            <span class="item-name lvl1">{{ item.name | translate }}</span>
            <span
              class="menuitem-badge mat-bg-{{ badge.color }}"
              [ngStyle]="{ background: badge.color }"
              *ngFor="let badge of item.badges"
              >{{ badge.value }}</span
            >
          </div>
          <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
        </a>
        <!-- LEVEL 2 -->
        <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'">
          <li *ngFor="let itemLvL2 of item.sub" appDropdownLink routerLinkActive="open">
            <a
              routerLink="{{ item.state ? '/' + item.state : '' }}/{{ itemLvL2.state }}"
              appDropdownToggle
              *ngIf="!itemLvL2.disabled && itemLvL2.type !== 'dropDown'"
              [id]="'side-nav-button-lvl2-item-dropdown-' + itemLvL2.name"
              matRipple>
              <mat-icon *ngIf="itemLvL2.icon">{{ itemLvL2.icon }}</mat-icon>
              <img *ngIf="itemLvL2.iconUrl" class="menu-icon" [src]="itemLvL2.iconUrl" />
              <span class="item-name lvl2">{{ itemLvL2.name | translate }}</span>
            </a>
            <a
              *ngIf="!itemLvL2.disabled && itemLvL2.type === 'dropDown'"
              class="flex-row flex-justify-content-space-between"
              appDropdownToggle
              matRipple
              [id]="'side-nav-button-lvl2-item-dropdown-' + itemLvL2.name">
              <div class="flex-row">
                <mat-icon *ngIf="itemLvL2.icon">{{ itemLvL2.icon }}</mat-icon>
                <img *ngIf="itemLvL2.iconUrl" class="menu-icon" [src]="itemLvL2.iconUrl" />
                <span class="item-name lvl2">{{ itemLvL2.name | translate }}</span>
              </div>
              <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
            </a>

            <!-- LEVEL 3 -->
            <ul class="submenu lvl3" appDropdown *ngIf="itemLvL2.type === 'dropDown'">
              <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                <a
                  *ngIf="!itemLvL3.disabled"
                  routerLink="{{ item.state ? '/' + item.state : '' }}{{
                    itemLvL2.state ? '/' + itemLvL2.state : ''
                  }}/{{ itemLvL3.state }}"
                  appDropdownToggle
                  [id]="'side-nav-button-lvl3-item-dropdown-' + itemLvL3.name"
                  matRipple>
                  <mat-icon *ngIf="itemLvL3.icon">{{ itemLvL3.icon }}</mat-icon>
                  <img *ngIf="itemLvL3.iconUrl" class="menu-icon" [src]="itemLvL3.iconUrl" />
                  <span class="item-name lvl3">{{ itemLvL3.name | translate }}</span>
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
