<div class="app-admin-wrap" [dir]="layoutConf?.dir">
  <!-- Header for top navigation layout -->
  <!-- ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT -->
  <app-header-top *ngIf="layoutConf.navigationPos === 'top'" [notificPanel]="notificationPanel"> </app-header-top>
  <!-- Main Container -->
  <mat-sidenav-container
    [dir]="layoutConf.dir"
    class="app-admin-container app-side-nav-container mat-drawer-transition sidebar-{{layoutConf?.sidebarColor}} topbar-{{layoutConf?.topbarColor}}"
    [ngClass]="{
    'navigation-top': layoutConf.navigationPos === 'top',
    'sidebar-full': layoutConf.sidebarStyle === 'full',
    'sidebar-compact': layoutConf.sidebarStyle === 'compact' && layoutConf.navigationPos === 'side',
    'compact-toggle-active': layoutConf.sidebarCompactToggle,
    'sidebar-compact-big': layoutConf.sidebarStyle === 'compact-big' && layoutConf.navigationPos === 'side',
    'sidebar-opened': layoutConf.sidebarStyle !== 'closed' && layoutConf.navigationPos === 'side',
    'sidebar-closed': layoutConf.sidebarStyle === 'closed',
    'fixed-topbar': layoutConf.topbarFixed && layoutConf.navigationPos === 'side'
  }">
    <!-- SIDEBAR -->
    <!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
    <app-sidebar-side
      *ngIf="layoutConf.navigationPos === 'side'"
      (mouseenter)="sidebarMouseenter($event)"
      (mouseleave)="sidebarMouseleave($event)"></app-sidebar-side>

    <!-- Top navigation layout (navigation for mobile screen) -->
    <!-- ONLY REQUIRED FOR **TOP** NAVIGATION MOBILE LAYOUT -->
    <app-sidebar-top *ngIf="layoutConf.navigationPos === 'top' && layoutConf.isMobile"></app-sidebar-top>

    <!-- App content -->
    <div class="main-content-wrap" id="main-content-wrap">
      <!-- Header for side navigation layout -->
      <!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
      <app-header-top
        *ngIf="layoutConf.navigationPos === 'side'"
        [notificPanel]="notificationPanel"
        [notifsNumber]="notifsNumber">
      </app-header-top>

      <div class="rightside-content-hold" id="rightside-content-hold">
        <!-- View Loader -->
        <div class="view-loader" *ngIf="isModuleLoading">
          <div class="spinner">
            <div class="double-bounce1 mat-bg-accent"></div>
            <div class="double-bounce2 mat-bg-primary"></div>
          </div>
        </div>
        <!-- Breadcrumb -->
        <app-breadcrumb></app-breadcrumb>
        <!-- View outlet -->
        <router-outlet></router-outlet>
      </div>
    </div>
    <!-- View overlay for mobile navigation -->
    <div
      class="sidebar-backdrop"
      [ngClass]="{'visible': layoutConf.sidebarStyle !== 'closed' && layoutConf.isMobile}"
      (click)="closeSidebar()"></div>

    <!-- Notificaation bar -->
    <mat-sidenav #notificationPanel mode="over" class="" position="end">
      <app-notifications
        [notificPanel]="notificationPanel"
        [notifications]="notifications"
        [notifsNumber]="notifsNumber"
        (updateNotifNumber)="updateNotifNumber($event)"
        (updateNotificationsArray)="updateNotificationsArray($event)">
      </app-notifications>
    </mat-sidenav>
  </mat-sidenav-container>
</div>
