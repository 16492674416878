import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';
import { RolesScreen } from '../enums/roles-screen.enum';

interface IMenuItem {
  type: string; // Possible values: link/dropDown/icon/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  nameOnDataBase?: string; // Used as matching name with role rights
  state?: string; // Router state
  icon?: string; // Material icon name
  iconUrl?: string; // icon url
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  type?: string;
  name: string; // Display text
  nameOnDataBase?: string; // Used as matching name with role rights
  state?: string; // Router state
  icon?: string;
  iconUrl?: string; // icon url
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  constructor(private authService: AuthService) {}
  iconMenu: IMenuItem[] = [];

  separatorMenu: IMenuItem[] = [];

  plainMenu: IMenuItem[] = [
    {
      name: 'common.quick_access',
      nameOnDataBase: RolesScreen.DASHBOARD,
      type: 'link',
      tooltip: 'Dashboard',
      iconUrl: './assets/images/sidebar-icons/quick_access.svg',
      state: 'dashboard',
    },
    {
      name: 'General_View.title',
      nameOnDataBase: RolesScreen.GENERAL_VIEW,
      type: 'link',
      tooltip: 'General_View.title',
      iconUrl: './assets/images/sidebar-icons/general_view.svg',
      state: 'general-view',
    },
    {
      name: 'Devices.title',
      nameOnDataBase: RolesScreen.DEVICES,
      type: 'dropDown',
      tooltip: 'Devices.title',
      iconUrl: './assets/images/sidebar-icons/devices.svg',
      state: 'devices',
      sub: [
        {
          name: 'Devices.inventory',
          nameOnDataBase: RolesScreen.INVENTORY,
          state: 'inventory',
          iconUrl: './assets/images/sidebar-icons/inventory.svg',
        },
        {
          name: 'Devices.assignments',
          nameOnDataBase: RolesScreen.ASSIGNMENTS,
          state: 'assignments',
          iconUrl: './assets/images/sidebar-icons/assignments.svg',
        },
      ],
    },
    {
      name: 'Human_Resources.title',
      nameOnDataBase: RolesScreen.HR,
      type: 'dropDown',
      tooltip: 'Human_Resources.title',
      iconUrl: './assets/images/sidebar-icons/hr_trans.svg',
      state: 'hr',
      sub: [
        {
          name: 'Agents.title',
          nameOnDataBase: RolesScreen.AGENTS,
          state: 'agents',
          iconUrl: './assets/images/sidebar-icons/agents_trans.svg',
        },
        {
          name: 'Teams.title',
          nameOnDataBase: RolesScreen.TEAMS,
          state: 'teams',
          iconUrl: './assets/images/sidebar-icons/team_trans.svg',
        },
        {
          name: 'Shifts.title',
          nameOnDataBase: RolesScreen.SHIFTS,
          state: 'shifts',
          iconUrl: './assets/images/sidebar-icons/shifts_trans.svg',
        },
        {
          name: 'Calendar.title',
          nameOnDataBase: RolesScreen.CALENDAR,
          state: 'calendar',
          iconUrl: './assets/images/sidebar-icons/calendar_icon.svg',
        },
        {
          name: 'Planning.title',
          nameOnDataBase: RolesScreen.PLANNING,
          state: 'planning',
          iconUrl: './assets/images/sidebar-icons/planning_32px_white.svg',
        },
      ],
    },
    {
      name: 'Infrastructure.title',
      nameOnDataBase: RolesScreen.INFRASTRUCTURE,
      type: 'dropDown',
      tooltip: 'Infrastructure.title',
      iconUrl: './assets/images/sidebar-icons/infrastructure_trans.svg',
      state: 'infrastructure',
      sub: [
        {
          name: 'common.lines',
          nameOnDataBase: RolesScreen.LINES,
          state: 'lines',
          iconUrl: './assets/images/sidebar-icons/line_trans.svg',
        },
      ],
    },
    {
      name: 'AI_Configuration.title',
      nameOnDataBase: RolesScreen.CONFIGURATIONS,
      type: 'dropDown',
      tooltip: 'AI_Configuration.title',
      iconUrl: './assets/images/sidebar-icons/AI_configuration.svg',
      state: 'ai-configuration',
      sub: [
        {
          name: 'AI_Configuration.network.title',
          nameOnDataBase: RolesScreen.CONFIG_NETWORK,
          type: 'dropDown',
          iconUrl: './assets/images/sidebar-icons/network.svg',
          state: 'network',
          sub: [
            {
              name: 'AI_Configuration.network.definition.title',
              nameOnDataBase: RolesScreen.DEFINITION,
              state: 'definition',
              iconUrl: './assets/images/sidebar-icons/definition.svg',
            },
            {
              name: 'AI_Configuration.network.ai_parameters.title',
              nameOnDataBase: RolesScreen.AI_PARAMETERS,
              state: 'ai_parameters',
              iconUrl: './assets/images/sidebar-icons/ai_parameters.svg',
            },
            {
              name: 'AI_Configuration.network.topology.title',
              nameOnDataBase: RolesScreen.TOPOLOGY,
              state: 'topology',
              iconUrl: './assets/images/sidebar-icons/topology.svg',
            },
            {
              name: 'AI_Configuration.network.objectives.title',
              nameOnDataBase: RolesScreen.OBJECTIVES,
              state: 'objectives',
              iconUrl: './assets/images/sidebar-icons/objectives.svg',
            },
          ],
        },
        {
          name: 'AI_Configuration.operations.title',
          nameOnDataBase: RolesScreen.CONFIG_OPERATIONS,
          type: 'dropDown',
          iconUrl: './assets/images/sidebar-icons/operations.svg',
          state: 'operations',
          sub: [
            {
              name: 'AI_Configuration.blacklisted_stations',
              nameOnDataBase: RolesScreen.BLACKLISTED_STATIONS,
              state: 'blacklisted-stations',
              iconUrl: './assets/images/sidebar-icons/remove-station-w.svg',
            },
            {
              name: 'AI_Configuration.mandatory_stations',
              nameOnDataBase: RolesScreen.MANDATORY_STATIONS,
              state: 'mandatory-stations',
              iconUrl: './assets/images/sidebar-icons/mandatory-stations.svg',
            },
          ],
        },
      ],
    },
    {
      name: 'Fraud_Control.title',
      nameOnDataBase: RolesScreen.FRAUD_CONTROL,
      type: 'dropDown',
      tooltip: 'Fraud_Control.title',
      iconUrl: './assets/images/sidebar-icons/fraud_control_trans.svg',
      state: 'fraud-control',
      sub: [
        {
          name: 'Contraventions.title',
          nameOnDataBase: RolesScreen.CONTRAVENTIONS,
          state: 'contraventions',
          iconUrl: './assets/images/sidebar-icons/contraventions.svg',
        },
        {
          name: 'Control_Statistics.title',
          nameOnDataBase: RolesScreen.CONTROL_STATISTICS,
          state: 'control-statistics',
          iconUrl: './assets/images/sidebar-icons/control_activity.svg',
        },
        {
          name: 'Extractions.title',
          nameOnDataBase: RolesScreen.EXTRACTIONS,
          state: 'extractions',
          iconUrl: './assets/images/sidebar-icons/extractions.svg',
        },
        {
          name: 'Activity_Reports.title',
          nameOnDataBase: RolesScreen.ACTIVITY_REPORTS,
          state: 'activity-reports',
          iconUrl: './assets/images/sidebar-icons/activity_reports.svg',
        },
        {
          name: 'Dars_reports.title',
          nameOnDataBase: RolesScreen.DARS_REPORTS,
          state: 'dars-reports',
          iconUrl: './assets/images/sidebar-icons/dars_reports.svg',
        },
        {
          name: 'Dars_reports_statistics.bus.title',
          nameOnDataBase: RolesScreen.DARS_REPORTS_STATISTICS_BUS,
          state: 'dars-reports-statistics-bus',
          iconUrl: './assets/images/sidebar-icons/dars_reports_statistics.svg',
        },
      ],
    },
    {
      name: 'incidents.title',
      nameOnDataBase: RolesScreen.INCIDENTS,
      type: 'link',
      tooltip: 'incidents.title',
      iconUrl: './assets/images/sidebar-icons/incident.svg',
      state: 'incidents',
    },
  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.plainMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    switch (menuType) {
      case 'separator-menu':
        this.menuItems.next(this.separatorMenu);
        break;
      case 'icon-menu':
        this.menuItems.next(this.iconMenu);
        break;
      default:
        this.menuItems.next(this.plainMenu);
    }
  }

  checkRights(): Observable<any> {
    const currentUser = this.authService.getTokenData();
    if (currentUser) {
      this.plainMenu.map(item => {
        item.disabled = !(
          currentUser.rights.hasOwnProperty(item.nameOnDataBase) && currentUser.rights[item.nameOnDataBase]
        );
        if (item.sub) {
          item.sub.map(child => {
            child.disabled = !(
              currentUser.rights.hasOwnProperty(child.nameOnDataBase) && currentUser.rights[child.nameOnDataBase]
            );
            if (child.sub) {
              child.sub.map(childOfChild => {
                childOfChild.disabled = !(
                  currentUser.rights.hasOwnProperty(childOfChild.nameOnDataBase) &&
                  currentUser.rights[childOfChild.nameOnDataBase]
                );
              });
            }
          });
        }
        return item;
      });
      const menuItems = new BehaviorSubject<IMenuItem[]>(this.plainMenu);
      return menuItems.asObservable();
    }
  }
}
